<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="17">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="门店名称">
                        <el-input v-model="form.store_name"></el-input>
                        <!--                        <el-checkbox v-model="oss_arrangement">允许平台排课</el-checkbox>-->
                    </el-form-item>
                    <el-form-item label="门店视频">
                        <Qnupload v-model="form.store_video" type="video"/>
                        <el-checkbox v-model="store_video_carousel">门店视频放到轮播图</el-checkbox>
                    </el-form-item>
                    <el-form-item label="门店照片">
                        <Qnupload v-model="form.store_photo" :sum="5" :isclipper="true" :fixedNumber="[4,3]"/>
                        <span class="form-tip">图片尺寸1024*768 或 宽:高≈4:3</span>
                    </el-form-item>
                    <el-form-item label="负责人">
                        <el-input v-model="form.responsible"></el-input>
                    </el-form-item>
                    <el-form-item label="门店电话">
                        <el-input v-model="form.store_phone"></el-input>
                    </el-form-item>
                    <el-form-item label="地址选择">
                        <Region ref="region" v-model="form.region_code" :onlyallow="1" v-on:region="getregion"/>
                    </el-form-item>
                    <el-form-item v-if="!this.Tool.is_empty(form.city)" label="地址">
                        <el-input v-model="form.address" @change="getposition"></el-input>
                    </el-form-item>
                    <el-form-item label="地图">
                        <Amap ref="amap_edit" idindex="edit" style="height: 600px;"/>
                        <span v-if="!this.Tool.is_empty(this.form.longitude)">经纬度:&nbsp;&nbsp;
                            <span>{{ this.form.longitude }}</span>&nbsp;,&nbsp;<span>{{ this.form.latitude }}</span>
                        </span>
                    </el-form-item>
                    <el-form-item label="交通信息">
                        <el-input v-model="form.trafficInfo" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="门店简介">
                        <el-input v-model="form.introduce" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                        <!--                        <Wangeditor ref="wangeditor_introduce" v-model="form.introduce" idindex="introduce"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item label="注意事项">
                        <el-input v-model="form.attention" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                        <!--                        <Wangeditor ref="wangeditor_attention" v-model="form.attention" idindex="attention"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item>
                        <el-button v-if="is_auth('store.store.issave')"
                                   size="medium" type="primary" @click="save">保存
                        </el-button>

                        <el-button v-if="!this.Tool.is_empty(this.store_uuid)" size="medium"
                                   @click="()=>{ this.$emit('toinfo')}">返回
                        </el-button>
                        <el-button v-else size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'
import Amap from '@/components/Amap'

export default {
    components: {
        // Wangeditor,
        Qnupload,
        Region,
        Amap
    },
    data() {
        return {
            select_tag: '',
            tag_list: [],
            loading: true,
            store_uuid: '',
            form: {
                store_video: '',
                store_photo: [],
                store_tag: [],
            },
            store_video_carousel: false,    // 视频是否加入轮播图
            oss_arrangement: false,          // 允许平台排课
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()
            } else {
                this.loading = false
            }
        },
        // 门店详情
        getinfo() {
            let thi = this
            let postdata = {
                api_name: "store.store.getinfo",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.region.init(json.data.region_code);                  // 地区选择
                    // this.$refs.wangeditor_introduce.append(json.data.introduce);    // 门店简介
                    // this.$refs.wangeditor_attention.append(json.data.attention);    // 注意事项

                    this.store_video_carousel = json.data.store_video_carousel === 1 ? true : false      //视频是否加入轮播图
                    this.oss_arrangement = json.data.oss_arrangement === 1 ? true : false                //是否允许平台排课

                    setTimeout(function () {
                        thi.getposition()           // 地图定点
                    }, 1000);
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 地址
        getregion(region_date) {
            this.form.province = region_date.province.region_level
            this.form.city = region_date.city.region_level
            this.form.county = region_date.county.region_level
        },
        // 获取坐标
        getposition() {
            let thi = this
            let address = this.form.city + this.form.address
            this.$refs.amap_edit.address2position(address, (json) => {
                if (json) {
                    thi.form.longitude = json.lng
                    thi.form.latitude = json.lat
                    this.$refs.amap_edit.marker([json.lng, json.lat, thi.form.store_name])
                } else {
                    thi.form.longitude = ''
                    thi.form.latitude = ''
                    this.$message({
                        message: '根据地址查询位置失败', type: 'error',
                        duration: this.env.message_error,
                    });
                }
            });
        },
        //保存
        save() {
            let postdata = {
                api_name: "store.store.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);

            postdata.store_video_carousel = this.store_video_carousel ? 1 : 0   // 视频是否加入轮播图
            postdata.oss_arrangement = this.oss_arrangement ? 1 : 0         // 是否允许平台排课

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // 判断是否有门店id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                            if (this.Tool.is_empty(this.store_uuid)) {
                                this.isreturn();        // 返回到列表
                            } else {
                                this.$emit('toinfo')    // 返回到详情
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/store/store'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
